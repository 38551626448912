<template>
    <div class="app-header header-shadow">
        <img v-if="deviceWidth < miniWidth" style="position: absolute; margin-left: auto; margin-right: auto; left: 0; right: 0;" width="110" class="text-center" src="@/assets/images/logo_name.png" alt="">
        <div class="app-header__content">
            <div class="app-header-left">
                <!-- <SearchBox/> -->
            </div>
    
        
            <div class="app-header-right">
                <UserArea/>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <b-button class="btn-icon btn-icon-only" variant="primary" size="sm" v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
                    <div class="btn-icon-wrapper">
                        <font-awesome-icon icon="ellipsis-v"/>
                    </div>
                </b-button>
            </span>
        </div>
    </div>
</template>

<script>

    // import SearchBox from './Header/SearchBox';
    import UserArea from './Header/HeaderUserArea';

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faEllipsisV,
    } from '@fortawesome/free-solid-svg-icons'
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faEllipsisV,
    );
    export default {
        name: "Header",
        components: {
            // SearchBox,
            UserArea,
            'font-awesome-icon': FontAwesomeIcon,
        },

        data() {
            return {
                isOpen: false,
                isOpenMobileMenu: false,
                miniWidth: 950,
				deviceWidth: window.innerWidth,
            }
        },
        props: {


        },
        created() {
			this.onResize();
		},
        mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
        methods: {
            toggleMobile(className) {
                const el = document.body;
                this.isOpen = !this.isOpen;

                if (this.isOpen) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },

            onResize() {
				this.deviceWidth = window.innerWidth;
			},

            toggleMobile2(className) {
                const el = document.body;
                this.isOpenMobileMenu = !this.isOpenMobileMenu;

                if (this.isOpenMobileMenu) {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            },
        }
    };
</script>
